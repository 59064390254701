<template>
  <div class="about-page">
    <div class="about-page__wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <pre>{{message}}</pre>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        message: ''
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.message = data.data.about
        })
      }
    }
  }
</script>
<style lang="less">
  @media screen and (max-width: 1500px) {
    .about-page {
      &__wrapper {
        width: 79% !important;
      }
    }
  }
  .about-page {
    background: #ffffff;
    width: 100%;
    padding: 32px 0;
  
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
    
      .el-breadcrumb {
        margin-bottom: 16px;
        font-size: 18px;
        padding-bottom: 16px;
        border-bottom: 1px solid #cccccc;
      }
    
      .content {
        font-size: 18px;
        letter-spacing: 3px;
        line-height: 30px;
        overflow-wrap: anywhere;
        p {
          letter-spacing: 2px;
          line-height: 28px;
        }
      }
    }
  }
</style>
